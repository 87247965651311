export default function Hidden_Page({reset}) {

    return (
        <div className='hidden-page'>
            <div className='hidden-inner'>
                <div className='background-overlay'></div>
                <div className="hidden-page-content">
                    <h1>the mother of all secrets</h1>
                    <p>No, you’re not getting a recycled secret. You’re getting a real deal,
                        one-of-one, super classified tidbit. Once a secret is told here, it disappears
                        into the void forever. Every secret on this site is only shown once.
                        <span>Take yours to your grave. This is serious (top secret) business.</span>
                    </p>
                    <button className='reset-button' onClick={reset}>Tell Your Secret</button>
                </div>

            </div>
            <p className="footer">*This page is not an outlet for mental health-related
                concerns. While those are very important, you should take them to a licensed
                professional instead of a microsite on the internet.</p>

        </div>
    )
}