import logo from './logo.svg';
import './App.css';
import './newCss.css';
import React, {useEffect} from 'react';
import {useState} from 'react';
import Start_Page from './modules/start';
import Add_Secret from './modules/add_secret';
import Reveal_Secret from './modules/secret_reveal';
import Hidden_Page from './modules/hidden_page';

function App() {
    const resetApp = () => {
        setStep(2);
        setCurrentSecret(null)
    }
    const [step,
        setStep] = useState(1);
    const [currentSecret,
        setCurrentSecret] = useState(null);
   



    const loadSecret = (url) => {
        let fetchUrl = "https://images.anthonygray.design/api/request.php?secret=";
        //let fetchUrl = "localhost:80/api/request.php?secret=";//for local
        fetchUrl += url;
        setCurrentSecret('Please Wait. Loading.....')
        console.log(fetchUrl);
 
        fetch(fetchUrl)
        .then(response => {
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        return response.json();
        })
        .then(data => {
        setCurrentSecret(data['secret']);
      //  console.log(data['secret'])
       // console.log(currentSecret)
        })
        .catch(error => {
        console.error('error:', error);
        
        });
      
        };//end load secret

    if (step == 3) {
        return (
            <Hidden_Page reset={resetApp}></Hidden_Page>
        )
    }
    if (currentSecret) {

        return (<Reveal_Secret secret={currentSecret} reset={resetApp} changeState={setStep}/>)
    }
    if (step == 1) {
        return (<Start_Page changeState={setStep}/>)
    }
    if (step == 2) {
        return (<Add_Secret changeState={setStep} setSecret={setCurrentSecret} loadSecret={loadSecret}/>)
    }

}

export default App;
