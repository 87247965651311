import React from "react"
import Hidden_link from "./hidden_link"
export default function Start_Page({changeState = f =>f}){
    return(
        <div className='App'>
        <div className='start-screen'>
            <Hidden_link change={changeState}></Hidden_link>
            <img src='images/psst2.png' alt='psst' id='psst' onClick={()=>{changeState(2)}}></img>
          
            

        </div>

    </div>
        )
}