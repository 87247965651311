import React from "react";
import Hidden_link from "./hidden_link";
export default function Reveal_Secret({
    secret,
    reset,
    changeState = f => f
}) {
    return (<>
        <Hidden_link change={changeState}></Hidden_link>
        <div className='show-secret page'>

            
            <div className="page-content">
                <div className="underlay"></div>
                <div className="content">
                    <div className="number-holder">
                        <p className="font-45">96</p>
                    </div>
                    <div className="secret-intro large">
                        <p>A little birdie wants
                            <br></br>to let you know ...</p>
                    </div>
                    <div className="the-secret">
                        <p
                            style={{
                            flexShrink: 1
                        }}>
                            {secret}</p>
                    </div>

                    <div className='bottom-text last-page'>
                        <button onClick={reset}>UNBURDEN YOURSELF AGAIN</button>
                        <br></br>
                        <p>nothing makes us
                            <br></br>so lonely<br></br>as our secrets.</p>
                    </div>
                </div>

            </div>

            <p className="footer">*This page is not an outlet for mental health-related
                concerns. While those are very important, you should take them to a licensed
                professional instead of a microsite on the internet.</p>
        </div>
        </>
    )
}