import React, {useState} from "react";
import Hidden_link from "./hidden_link";

export default function Add_Secret({
    setSecret = f => f,
    setStep = f => f,
    changeState = f => f,
    loadSecret = f =>f
}) {
    const [inputVal,
        setInputVal] = useState('');
    const loadResponse = (e) => {
        e.preventDefault();
        setSecret(inputVal);
        loadSecret(inputVal);
        //console.log(inputVal)
        setInputVal('')
        //console.log('submit')
    } //end load response

    return (
        <div className='add-secret page'>

            <Hidden_link change={changeState}></Hidden_link>
            <div className="page-content">
                <div className="underlay"></div>
                <div className="content">
                    <div className="number-holder">
                        <p className="font-45">86</p>
                    </div>
                    <div className="secret-intro large">
                        <p>wanna know a secret?<br></br>you've gotta tell us<br></br>
                            yours first.</p>
                    </div>
                    <form onSubmit={loadResponse}>
                     {/*  <input type="text" maxLength="200" onChange={e=>(setInputVal(e.target.value))} value={inputVal}></input> */}
                     <textarea className='input' type="text" maxLength="200" onChange={e=>(setInputVal(e.target.value))} value={inputVal}></textarea>
                        <button type="submit" className='submit-secret' >QUID PRO QUO</button>
                    </form>
                    <div className='bottom-text'>
                        <p>nothing makes us <br></br>so lonely<br></br>as our secrets.</p>
                    </div>
                </div>

            </div>

            <p className="footer">*This page is not an outlet for mental health-related
                concerns. While those are very important, you should take them to a licensed
                professional instead of a microsite on the internet.</p>
        </div>
    )
}